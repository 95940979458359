<template>
    <div class="maintenance-details">
        <header class="page-header">
            <div>
                <client-account-header></client-account-header>
                <h2>Service Request Details</h2>
            </div>
        </header>
        <div class="content-wrapper">
            <headless-table :record="serviceRequest" :columns="columns"></headless-table>
        </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core';
import ClientAccountHeader from '../../components/clientAccounts/ClientAccountHeader.vue'
import HeadlessTable from '../../components/tables/HeadlessTable.vue';
import store from '../../store/store';
import ServiceRequestModel from '../../models/ServiceRequestModel';
import { useRoute } from 'vue-router';

export default {
  components: { ClientAccountHeader, HeadlessTable },
    setup(){
        const route = useRoute();
        
        const promises = [];
        const loaded = ref(false);


        const columns = {
            'id': {
                title: "Request #",
                sortable: true,
                link: '/service-history/'
            },
            'status' :{
                title: "Status",
                sortable: true,
            },
            'createdDate': {
                title: "Created Date",
                sortable: true,
            },
            'workOrders.workOrderNumber' : {
                title : "Work order #(s)",
                sortable: 'equipmentIssues.acceptedBy.workOrder.workOrderNumber',
                link: '/work-orders/'
            },
            'equipment.trailerNumber': {
                title: "Trailer #(s)",
                sortable: 'equipmentIssues.equipment.trailerNumber',
                link: '/equipment/'
            },
            'invoice.invoiceNumber' : {
                title : "Invoice #",
                sortable: true,
                link: '/invoices/',
                NA: 'Not invoiced'
            },
            'invoice.invoiceDate' : {
                title: "Invoice date",
                sortable: true,
                NA: '--'
            },
            'invoice.purchaseOrderNumber' : {
                title: "PO Number",
                sortable: true,
                NA: '--'
            },
            'invoice.total' : {
                title: "Amount",
                sortable: true,
                format: 'money'
            },

        }

        const serviceRequestId = route.params.id;
        if (!serviceRequestId){
            route.push('/service-history/');
        }
        promises.push(store.dispatch('get', {model: ServiceRequestModel, id: serviceRequestId }));
        const serviceRequest = computed(() => {
            return store.state.AppData[ServiceRequestModel.name][serviceRequestId];
        });

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {columns, serviceRequest};
    }
}

// import MaintenanceModel from '../../models/MaintenanceModel.js'
// import HeadlessTable from '../../components/tables/HeadlessTable.vue';


// import axios from 'axios';



// export default {
//     components: {
//         HeadlessTable
//     },
//     data: function(){

//         return {
//             model : new MaintenanceModel(),
//             record: this.$root.$data[MaintenanceModel.name][this.$route.params.id] = Object.hasOwnProperty.call(this.$root.$data[MaintenanceModel.name], this.$route.params.id) ? this.$root.$data[MaintenanceModel.name][this.$route.params.id] : new MaintenanceModel(),
//             columns
//         }
//     },
//     mounted: function(){
//         console.log("mounted");
//     },
//     created: function(){
//         console.log("created");
//         if (!Object.hasOwnProperty.call(this.$root.$data[MaintenanceModel.name], this.$route.params.id)){
//             this.$root.$data[MaintenanceModel.name][this.$route.params.id] = new MaintenanceModel();
//         }
//         axios.get('https://mockapi.starleasing.dev/maintenance').then((response) => {
//             this.$root.$data[MaintenanceModel.name].populate(response.data);
//         }, (e) => {
//             console.log(e);
//             //!! need handler for errors
//         });
//     }
// }
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>